<template>
  <div class="space-y-10">
    <div class="lg:grid lg:grid-cols-3 gap-12 mt-4">
      <!-- Knightsbrook Hotel -->
      <div class="space-y-8">
        <h1 class="font-semibold text-3xl">
          Knightsbrook Hotel, Spa, &amp; Golf Resort
        </h1>
        <p>Dublin Road, Trim Ireland</p>
        <p>
          Located in Trim, the Knightsbrook Hotel is set in 186 acres of rolling
          parkland. It offers a Championship golf course, luxurious bedrooms,
          fine-dining, pool, and a spa and health club.
        </p>
        <p>
          The air-conditioned rooms at The Knightsbrook Hotel &amp; Golf Resort
          are beautifully furnished and feature flat-screen satellite TV and
          free Wi-Fi. They include luxury bathrooms with designer toiletries and
          there is also a 24-hour room service menu available.
        </p>
        <p>
          The River Spa offers a range of massages, body wraps and exotic
          treatments. The health club features a sauna, hot tub and a modern
          gym, there are also regular fitness classes. Christy O’Conor Jnr.
          designed the hotels golf course.
        </p>
        <p>
          The Rococo Restaurant offers a sophisticated dining experience, while
          the Terrace Lounge has a light menu and stunning views. Guests can
          unwind with a drink at Gulliver’s or Swift’s Bar.
        </p>
        <p>
          The Hill of Tara and Bective Abbey are within a 5-minute drive.
          Horse-riding, fishing and beautiful walking routes are all available
          locally.
        </p>
        <hr />
        <!-- icons -->
        <div class="grid grid-cols-2 gap-4">
          <div class="flex items-center space-x-3">
            <i class="mdi mdi-wifi text-lg"></i>
            <span>Free Wi-Fi</span>
          </div>
          <div class="flex items-center space-x-3">
            <i class="mdi mdi-parking text-lg"></i>
            <span>Free Parking</span>
          </div>
          <div class="flex items-center space-x-3">
            <i class="mdi mdi-pool text-lg"></i>
            <span>Pool</span>
          </div>
          <div class="flex items-center space-x-3">
            <i class="mdi mdi-glass-cocktail text-lg"></i>
            <span>Bar / Lounge</span>
          </div>
        </div>
        <hr />
        <!-- buttons  -->
        <div class="grid grid-cols-1 sm:grid-cols-2 gap-6 lg:grid-cols-1">
          <div>
            <router-link
              :to="'/' + $store.state.selectedPartner.id + '/p/' + 'ir2bd'"
              class="
                uppercase
                bg-pink-600
                text-pink-50
                hover:bg-pink-700
                font-semibold
                cursor-pointer
                text-sm
                tracking-wide
                transition
                rounded-full
                px-4
                py-3
                block
                text-center
              "
            >
              View Our 7 Night Knightsbrook Package
            </router-link>
          </div>
          <div>
            <router-link
              :to="'/' + $store.state.selectedPartner.id + '/p/' + '2d200'"
              class="
                uppercase
                bg-purple-600
                text-purple-50
                hover:bg-purple-700
                font-semibold
                cursor-pointer
                text-sm
                tracking-wide
                transition
                rounded-full
                px-4
                py-3
                block
                text-center
              "
            >
              View Our 7 Night Maui / Ireland Package
            </router-link>
          </div>
          <div>
            <router-link
              :to="'/' + $store.state.selectedPartner.id + '/europe-ireland'"
              class="
                uppercase
                bg-blue-600
                text-blue-50
                hover:bg-blue-700
                font-semibold
                cursor-pointer
                text-sm
                tracking-wide
                transition
                rounded-full
                px-4
                py-3
                block
                text-center
              "
            >
              Browse Europe &amp; Ireland Packages
            </router-link>
          </div>
          <div>
            <button
              @click="downloadPdf"
              :to="'/' + $store.state.selectedPartner.id + '/p/' + '2d200'"
              class="
                uppercase
                bg-gray-600
                text-gray-50
                hover:bg-gray-700
                font-semibold
                cursor-pointer
                text-sm
                tracking-wide
                transition
                rounded-full
                px-4
                py-3
                block
                text-center
                w-full
              "
            >
              Download Knightsbrook PDF
            </button>
          </div>
        </div>
      </div>
      <!-- pdf embed -->
      <div
        class="
          space-y-8
          col-span-2
          shadow
          md:overflow-y-auto md:h-3/4
          mt-10
          lg:mt-2
        "
      >
        <vue-pdf-embed :source="pdfSource" />
      </div>
      <!-- best sellers -->
      <div class="col-span-3 space-y-10">
        <div>
          <hr />
        </div>
        <div class="pageTitle">
          <h1>Our Best Selling Packages</h1>
          <h3>A great starting point for any event</h3>
        </div>
        <RetailPackages :items="$store.state.retailPackages" />
      </div>
    </div>
  </div>
</template>

<script>
import VuePdfEmbed from "vue-pdf-embed";
// https://www.npmjs.com/package/vue-pdf-embed
import RetailPackages from "@/components/RetailPackages";
export default {
  components: { RetailPackages, VuePdfEmbed },
  data: () => ({
    pdfSource:
      "https://firebasestorage.googleapis.com/v0/b/silent-auction-vacations.appspot.com/o/pdfs%2Fknightsbrook.pdf?alt=media&token=cb8ccd45-92bc-475e-bc74-ddda2c916c6a",
  }),
  methods: {
    downloadPdf() {
      if (!this.pdfSource) return;
      let win = window.open(this.pdfSource, "_blank");
      win.focus();
    },
  },
};
</script>
